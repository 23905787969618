export const IFAKS_GOAL = 10512;
export const IFAKS_SHIPPED = 9248;
export const FUNDS_COLLECTED = '11 816 476';

// oldboi: 166 334 + 180 000 + 265 000 + 568 000 + 370 000 + 266 331

export const UA = 'ua';
export const EN = 'en';

export const UAH = 'uah';
export const USD = 'usd';
export const EURO = 'euro';
